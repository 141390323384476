<template>
    <div class="w-full py-5" :style="'background: #' + data.Background">
        <div class="container">
            <div class="flex justify-center">
                <span class="text-3xl font-bold">{{ data.Title }}</span>
            </div>
            <div class="flex justify-center">
                <p class="text-center">{{ data.Text }}</p>
            </div>
            <div :class="['flex w-full mt-4', { 'flex-col md:flex-row': data.Grid }]" v-if="windowWidth > 600 || data.Grid">
                <div :class="['w-full', 'md:w-1/' + item.Column]" v-for="(item, n) in data.Data" :key="n">
                    <icon-text-component :item="item" />
                </div>
            </div>
            <div v-else>
                <icon-text-swiper :data="data.Data" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        IconTextComponent: () => import('./IconTextComponent'),
        IconTextSwiper: () => import('./IconTextSwiper')
    },
    props: {
        data: {
            type: Object
        }
    }
}
</script>
<style lang="scss" scoped>
    ::v-deep .icon {
        height: fit-content
    }
    ::v-deep .carousel {
        min-height: fit-content;
    }
    ::v-deep .carousel-list {
        box-shadow: none !important;
    }
</style>
